import React from "react"
import { Link } from "gatsby"

import CesiumContainer from "./cesiumContainer"
import CesiumContainer2 from "./cesiumContainer2"

import Layout from "../components/layout"
import SEO from "../components/seo"

const kml1 = 'https://empire-dev3.kashika.net/kml/'+'105'+'/'+'福岡県久留米市' + '_' + 'H26小売業販売額×公共交通利用圏.kmz';

const User = props => (
  <div>
    <Link to={props.url}>
      <div>
          <img src={props.avatar}alt="" />
        <div>
          <h2><span>{props.themename}</span>{props.username}</h2>
          <p>{props.excerpt}</p>
        </div>
      </div>
    </Link>
  </div>
  )
  

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>新プレビュー画面テスト</h1>
    <p>新プレビュー画面をご確認ください</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    </div>

<div style={{display:"flex"}}>

    <div id="cesium1">
    <CesiumContainer
      dir="/kml"
      file="/sample.kml"
      kmlurl="/kml/sample.kml"
    />
    <Link
     to="/cesiumWindow"
     state={{
       dir:"/kml",
       file:"/sample.kml",
      }}
    >
      全画面表示</Link>
      </div>
      <div id="cesium2">
        <div>
          
        </div>
    <CesiumContainer2
      dir="/kml"
      file="/sample2.kml"
    />
    <Link
     to="/cesiumWindow"
     state={{
      dir:"/kml",
      file:"/sample2.kml"
     }}
    >
      全画面表示</Link>
      </div>
    </div>

  </Layout>
)



export default IndexPage
